// Use CSS Variables example usage:
// color: var(--brand-core);
// You can add a fallback as a second argument if the variable doesn't exist.
// color: var(--brand-core, $primary-core);

:root {
  --brand-core: #{$primary-core}; // Main brand colour
  --brand-dark: #{$primary-dark}; // Darkened main brand colour for hover effects
  --brand-font-color: #{$font-color-dark}; // Text colour to go ontop of brand-core
  --site-background-color: #{$site-background-color-dark}; // Site background colour on dark theme
  --site-background-color-secondary: #{$site-background-color-secondary-dark}; // Secondary site background colour on dark theme
  --site-font-family: 'Lato', 'Noto Sans JP', 'Noto Sans KR', 'Noto Sans SC', 'Noto Sans TC', 'Open Sans', 'Lucida Grande', 'Tahoma', sans-serif; // Site font family
  --site-font-color: #{$font-color-light}; // Site font colour on dark theme
  --site-font-color-mid: #{$grey-4}; // Site mid font colour on dark theme
  --site-font-weight-light: #{$font-weight-regular}; // Light site font weight on dark theme
  --site-font-weight-regular: #{$font-weight-medium}; // Medium site font weight on dark theme
  --site-font-weight-bold: #{$font-weight-bold}; // Bold site font weight on dark theme
  --input-border-color: #{$input-border-color}; // Input box border colour
  --card-background-color: #{$card-background-color-dark}; // HitCard background colour on dark theme
  --card-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12); // HitCard shadow
  --card-icons-color: #{$card-icons-color-light}; // DRM & OS icon colour on dark theme
  --percentage-background-color: #{$card-percentage-background-color-dark}; // Percentage box background colour on dark theme
  --tag-background-color: #{$tag-background-color-dark}; // Tag background colour on dark theme
  --tag-background-color-hover: #{$tag-background-color-dark-hover}; // Tag background colour on hover on dark theme
  --negative-gutter: -0.5rem;
  --gutter-padding: 0.5rem;
  --gutter-margin: 1rem;
  --gutter-gap: 1rem;
  --site-font-size-small: 13px;
  --site-font-size-regular: 14px;
  --button-font-size: 15px;
  --button-font-size-small: 14px;
  --showcase-background-color: #06151c;
  --showcase-background-color-light: #{lighten(#06151c, 2%)};
  --showcase-card-color: #253745;
  --showcase-card-border-radius: 20px;
  --showcase-font-family: 'PT Sans Narrow', sans-serif;
}

@media screen and (min-width: 992px) {
  :root {
    --negative-gutter: -0.75rem;
    --gutter-padding: 0.75rem;
    --gutter-margin: 1.5rem;
    --gutter-gap: 1.5rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --site-font-size-small: 14px;
    --site-font-size-regular: 16px;
    --button-font-size: 18px;
  }
}
@media screen and (min-width: 1520px) {
  :root {
    --negative-gutter: -1rem;
    --gutter-padding: 1rem;
    --gutter-margin: 2rem;
    --gutter-gap: 2rem;
  }
}

// SITE THEMES

.site-dark-theme {
  background-color: var(--site-background-color);
  color: var(--site-font-color);
  font-weight: var(--site-font-weight-regular);
  transition: all ease 0.3s;
}

.site-light-theme {
  --site-background-color: #{$site-background-color-light};
  --site-background-color-secondary: #{$site-background-color-secondary-light};
  --site-font-color: #{$font-color-dark};
  --site-font-color-mid: #{$grey-5};
  --site-font-weight-light: #{$font-weight-regular};
  --site-font-weight-regular: #{$font-weight-medium};
  --site-font-weight-bold: #{$font-weight-bold};
  --card-background-color: #{$card-background-color-light};
  --card-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  --card-icons-color: #{$card-icons-color-dark};
  --percentage-background-color: #{$card-percentage-background-color-light};
  --tag-background-color: #{$tag-background-color-light};
  --tag-background-color-hover: #{$tag-background-color-light-hover};

  background-color: var(--site-background-color);
  color: var(--site-font-color);
  font-weight: var(--site-font-weight-regular);
  transition: all ease 0.3s;
  min-height: 100vh;
}

// PARTNER BRAND THEMES

.brand-hpomen {
  --brand-core: #{$hpomen-core};
  --brand-dark: #{$hpomen-dark};
  --brand-font-color: #{$white};
  --site-font-family: #{$hpomen-font-family}; // Site font family

  font-family: var(--site-font-family); // This is needed because the original font is being inherited from the body
}

.brand-acer {
  --brand-core: #{$acer-core};
  --brand-dark: #{$acer-dark};
  --brand-font-color: #{$white};
}

.brand-gamespot {
  --brand-core: #{$gamespot-core};
  --brand-dark: #{$gamespot-dark};
  --brand-font-color: #{$white};
}

.brand-box {
  --brand-core: #{$box-core};
  --brand-dark: #{$box-dark};
  --brand-font-color: #{$white};
}

.brand-chilliblast {
  --brand-core: #{$chilliblast-core};
  --brand-dark: #{$chilliblast-dark};
  --brand-font-color: #09212d;
}

.brand-ccl {
  --brand-core: #{$ccl-core};
  --brand-dark: #{$ccl-dark};
  --brand-font-color: #{$white};
}

.brand-insidetech {
  --brand-core: #{$insidetech-core};
  --brand-dark: #{$insidetech-dark};
  --brand-font-color: #{$white};
}

.brand-stormforce {
  --brand-core: #{$stormforce-core};
  --brand-dark: #{$stormforce-dark};
  --brand-font-color: #{$black};
}

.brand-razer {
  --brand-core: #{$razer-core};
  --brand-dark: #{$razer-dark};
  --brand-font-color: #{$black};
}

.brand-powercolor {
  --brand-core: #{$powercolor-core};
  --brand-dark: #{$powercolor-dark};
  --brand-font-color: #{$white};
}
