@import "./colors-material";
@import "./colors";
@import "./variables";
@import "./themes";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/tooltip";

// BOOTSTRAP STYLE OVERWRITES

.container {
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @include media-breakpoint-down(xs) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.row {
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  @include media-breakpoint-up(sm) {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }

  @include media-breakpoint-up(lg) {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }

  // @include media-breakpoint-up(xl) {
  //   margin-right: -0.875rem;
  //   margin-left: -0.875rem;
  // }

  @include media-breakpoint-up(xxl) {
    margin-right: -1rem;
    margin-left: -1rem;
  }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl {
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  @include media-breakpoint-up(sm) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  // @include media-breakpoint-up(xl) {
  //   padding-right: 0.875rem;
  //   padding-left: 0.875rem;
  // }

  @include media-breakpoint-up(xxl) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.badge {
  line-height: 0.75rem;
}

.badge-primary {
  color: var(--brand-font-color);
  background-color: var(--brand-core);
}

.badge-danger {
  color: $white;
  background-color: $error-core;
}
